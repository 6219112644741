:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--Primarycolor: #d93;
	--Secondarycolor: #403281;
	--Themelight: #d93;
	--Reetlandred: #403281;
	--Darkgreen: #000000;
	--Lightgreen: #f3f3f3;
	--cal_not_available: #b32e31;
	--cal_free: #ccaf6e;
	--white: #fff;
	--black: #000;
	--menuitem: #d93;

	/*Calendar colors*/
	--not_available: #b32e31;
	--disabled-alt: #d0e9c7;
	--disabled: #387222;
	--available: #387222;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
	--text_color: #000;
}